import styled from "styled-components"

export const CoachOverviewContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
  padding-top: 150px;
  padding-bottom: 150px;

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 20px;
    padding-top: 140px;
    padding-bottom: 20px;
  }
  
  @media screen and (max-width: 600px) {
      padding: 0px;
      padding-top: 150px;
      padding-bottom: 20px;
    }

  .overview-image {
    display: flex;
    justify-content: flex-end;

    .overview-image-wrapper {
      background: white;
      padding: 10px;
      border-radius: 15px;
      width: 470px;
      height: 600px;
      box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);

      @media screen and (max-width: 600px) {
        width: 100%;
        height: 450px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }
  }
  
  .overview-content {
    display: flex;
    flex-direction: column;
    color: black;

    @media screen and (max-width: 600px) {
      .overview-cta {
        display: flex;
        justify-content: center;

        button {
          font-size: 16px;
        }
      }

      h1 {
        font-size: 30px;
      }
    }

    > * {
      margin-bottom: 20px;
    }
    
    p {
      font-weight: 400;
    }
    
    .overview-description-small {
      color: #A5B5AF;
    }
  }
`

export const Title = styled.h3`
  color: #142c1f;
  margin-bottom: 35px;
`;
