import React from "react";
import * as F from "./whyClientsLoveUs.style";

import StepCard from "components/General/stepCard/StepCard";
// Images
import ThumbsUp from 'assets/images/thumbs-up-icon.svg'
import Peace from 'assets/images/peace-icon.svg'
import LetsGo from 'assets/images/letsgo-icon.svg'

const WhyClientsLoveUs = () => {
  const List = [
    {
      title: "Menschlich",
      description:
        "Dein Wohlbefinden steht bei mir an erster Stelle. Ich kenne die Herausforderungen und Hürden. Ich bin immer für dich da und werde dich in allen Bereichen unterstützen.",
      icon: ThumbsUp,
    },
    {
      title: "Erfahrung",
      description:
        "Du erhältst wertvolle Tipps und Strategien aus über 50.000 Ernährungsumstellungen und im Coaching bei gesunder Ernährung und der Ernährungsumstellung.",
      icon: Peace,
    },
    {
      title: "Alltagstauglich",
      description:
        "Der perfekte Plan ist nutzlos, wenn er sich nicht einfach in den Alltag integrieren lässt. Aus diesem Grund achte ich auf deine Tagesroutinen!",
      icon: LetsGo,
    },
    {
      title: "Betreuung",
      description:
        "Die persönliche Betreuung wird bei mir großgeschrieben. Kein Algorithmus der Welt kann einen persönlichen Ansprechpartner ersetzen.",
      icon: ThumbsUp,
    },
    {
      title: "Preis/Leistung",
      description:
        "Eine gesunde Ernährung sollte für jeden zugänglich sein. Deswegen biete ich das Coaching für einen sehr geringeren Preis an. Fair und Transparent!",
      icon: Peace,
    },
    {
      title: "Motivierend",
      description:
        "Jede Ernährungsumstellung wird Höhen und Tiefen haben. Ich weiß, wie ich damit umgehen kann und gebe dir eine extra Portion Motivation auf den Weg mit.",
      icon: LetsGo,
    },
  ];
  return (
    <F.Wrap>
      <F.SectionTitle className="ct-text-block font-bold color-dark text-left text-3xl">
        Gründe, wieso meine, Klienten mich lieben
      </F.SectionTitle>
      <F.CardsWrap>
        {List.map((item, i) => (
          <StepCard
            key={i}
            title={item?.title}
            description={item?.description}
            icon={item?.icon}
          />
        ))}
      </F.CardsWrap>
    </F.Wrap>
  );
};

export default WhyClientsLoveUs;
