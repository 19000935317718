import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from './HollisticSupport.style'
import videoSource from 'assets/videos/support-video.mp4'
import ReactPlayer from "react-player";

const HollisticSupport = ({ changeOrder = false, onLeadFormModal }) => {
  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={ changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap" }
    >
      <div className="text-content">
        <Chip text="Gesamtpaket" margin="0 0 30px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Ganzheitliche Unterstützung in meinem All-In-One Angebot.
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          In meiner persönlichen Betreuung sind alle Leistungen, welche ebenfalls im Coaching PLUS und in der Ernährungsplaner-App verfügbar sind inkludiert.
          So biete ich dir eine ganzheitliche Betreuung mit digitalen Tools uns meiner persönlichen Unterstützung an.
          Zudem erhält du die App mit allen PRO Funktionen, für eine einfache Erfolgskontrolle.
        </C.Description>

        <C.Action>
          <button className="btn-dark" onClick={() => onLeadFormModal()}>Erstgespräch vereinbaren</button>
        </C.Action>

      </div>
      <C.VisualContent>
        {/* <img src={HollisticSupportBanner} alt="" /> */}
        <ReactPlayer
          url={videoSource}
          playsinline
          playing
          muted
          loop
        />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default HollisticSupport
