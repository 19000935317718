import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IS_LOCAL } from "configs/utils";
import HelpWithSurvey from "components/HelpWithSurvey/HelpWithSurvey";

// Components
import Navbar from "components/Navbar/Navbar";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FreeInitialConsultationWithSurvey from "components/FreeInitialConsultationWithSurvey/FreeInitialConsultationWithSurvey";
import FooterNew from "components/FooterNew/FooterNew";
import NewAttitude from "components/NewAttitude/NewAttitude";
import NutritionPlan from "components/NutritionPlan/NutritionPlan";
import RightCoach from "components/RightCoach/RightCoach";
import FirstConsultation from "components/FirstConsultation/FirstConsultation";
import ScheduleInitialConsultation from "components/ScheduleInitialConsultation/ScheduleInitialConsultation";
import NextSteps from "components/NextSteps/NextSteps";
import TestimonialSlider from "components/TestimonialSlider/TestimonialSlider";
import SectionWrapper from "components/General/sectionWrapper/SectionWrapper";
// Styles
import * as C from "./initialConsultation.styles";
import * as G from "styles/General.Styles";
// Redux
import * as CoachActions from "redux/coach/coach.actions";
import CoachingWithMe from "components/CoachingWithMe/CoachingWithMe";
import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import hook from "assets/images/hook.svg";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";

const InitialConsultation = (props) => {
  const dispatch = useDispatch();
  const coach = useSelector((state) => state.coach.coach);

  const [openLeadModal, setOpenLeadModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (coach) return

    const coach_website_url = IS_LOCAL
      ? 'https://ronja-fci49818.foodiary.app'
      : `${window.location.protocol}//${window.location.host}`

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: (res) => null,
            })
          );
        },
      })
    );
  }, [dispatch, coach]);

  return (
    <>
      <C.Header>
        <C.HeaderInner>
          <G.ContainerNavbar>
            <StickyNavbar
              openSurveyModal={() => setOpenLeadModal(!openLeadModal)}
            />
          </G.ContainerNavbar>
          <G.Container>
            <C.HeaderContentWrapper>
              <C.HeaderContent>
                <C.Chip>ERSTGESPRÄCH</C.Chip>
                <C.HeaderTitle className="ct-text-block font-bold color-dark text-center mt-8 text-5xl">
                  Lerne mich im <br /> Erstgespräch kennen
                </C.HeaderTitle>
                <C.HeaderDescription className="ct-text-block color-paragraph text-base text-center">
                  In einem 40-minütigen Erstgespräch lernen wir uns kennen, und ich erstelle 
                  <br />
                  dir kostenlos deinen persönlichen Ernährungsplan.
                </C.HeaderDescription>
              </C.HeaderContent>
              <div className="hook-block">
                <img width="100" src={hook} alt="hook" />
              </div>
            </C.HeaderContentWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>

      <G.Container>
        <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <NewAttitude />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <NextSteps onLeadFormModal={() => setOpenLeadModal(!openLeadModal)} />
        </SectionWrapper>
      
      </G.Container>

      <SectionWrapper margin="20rem 0" mobileMargin="5rem 0">
        <HelpWithSurvey>
          <div className="survey-content">
            <div className="survey-headline">
              <h3>Tritt in Kontakt mit mir</h3>
            </div>
            <div className="survey-description">
              <p>Als dein persönlicher Ernährungsberater helfe ich dir weiter, sodass du dein persönliches Ziel erreichst. Hierfür ist es zunächst wichtig zu wissen, welches Ziel du verfolgst und wie ich dich dabei unterstützen kannst.</p>
              <p>Verrate mir dein Ziel und deine Bedürfnisse unverbindlich über das Kontaktformular.</p>
            </div>
          </div>
        </HelpWithSurvey>
      </SectionWrapper>

      <G.Container>

        <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <FirstConsultation />
        </SectionWrapper>

        {/* <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <FreeInitialConsultationWithSurvey />
        </SectionWrapper> */}

        {/* <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <RightCoach />
        </SectionWrapper> */}

        <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <TestimonialSlider />
        </SectionWrapper>

      </G.Container>

      <SectionWrapper margin="20rem 0 0">
        <InitialConsultationBox openLeadFormModal={() => setOpenLeadModal(true)} />
      </SectionWrapper>

      <FooterNew />

      {coach && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coach}
        />
      )}
    </>
  );
};

export default InitialConsultation;
