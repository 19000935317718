import styled from "styled-components";

export const Title = styled.h3`
  color: #142c1f;
`;

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: center;

  &.NutritionCoachWrap {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 991.99px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;


export const VisualContent = styled.div`
  img {
    width: 100%;
    height: auto;
    border-radius: 30px;
  }

  @media (max-width: 991.99px) {
    max-width: 500px;
    width: 100%;
    margin-inline: auto;
    order: 1;
  }
`;

export const Image = styled.div`
  background: white;
  padding: 10px;
  border-radius: 15px;
  width: 470px;
  height: 600px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);

  @media screen and (max-width: 600px) {
    width: 100%;
    height: auto;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  > * {
    margin: 0px;
  }

  img {
    width: 100%;
  }

  @media (max-width: 991.99px) {
    order: 2;
  }
`;

export const PlanBoxIcons = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 30px;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  &.procedure {
    li {
      align-items: start;
    }
  }
`;

export const PlanBoxIconBox = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  img {
    width: 40px;
    height: 40px;
  }
`;

export const PlanBoxInformation = styled.span`
  color: #44554c;
  /* font-size: 1rem;
  font-weight: 400; */
  width: calc(100% - 40px);
  padding-left: 20px;

  &.ProcedureTop {
    /* font-size: 1.15rem !important; */
    padding-top: 10px;
  }
  &.StandatProgramText {
    /* font-size: 1.15rem !important; */
  }
`;
