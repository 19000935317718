import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from './PersonalSupport.style'
import PersonalSupportBanner from 'assets/images/personal-support.svg'
import { useHistory } from "react-router-dom";

const PersonalSupport = ({ changeOrder = false, onLeadFormModal }) => {

  const history = useHistory()

  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={ changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap" }
    >
      <div className="text-content">
        <Chip text="Persönliches Coaching" margin="0 0 30px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Persönliche Unterstützung für deine gesunde Ernährung.
        </C.Title>
        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Ich begleite dich im Alltag auf deinem Weg zu einer nachhaltigen und gesunden Ernährung.
          Im Coaching stehe ich dir mit individueller Unterstützung zur Seite und stelle dir zusätzlich hilfreiche Tools zur Verfügung,
          die deinen Alltag erleichtern.
        </C.Description>
        <br />
        <C.Description className="ct-text-block text-base mb-4 color-light-grey">
          Die Ernährungsplaner-App und die Lernacademy unterstützen dich zusätzlich mit smarten Funktionen und wertvollem Wissen.
        </C.Description>
        <C.Action>
          <button className="btn-dark" onClick={() => history.push('/coaching')}>Mehr erfahren</button>
        </C.Action>
      </div>
      <C.VisualContent>
        <img src={PersonalSupportBanner} alt="" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default PersonalSupport
