import backIcon from 'assets/images/back-icon.svg'
import { Link, useHistory } from 'react-router-dom'
import {
  getPreviousOnboardingPage,
  getNextOnboardingPage
} from '../../../routes/onboardingRoutes'
import * as Styles from './GenderStyles'
import { Checkbox } from 'antd'
import MaleIcon from 'assets/images/onboarding/Icon-male.svg'
import FemaleIcon from 'assets/images/onboarding/Icon-woman.svg'
import { useEffect, useState } from 'react'
import {
  getOnboardingStorage,
  setOnboardingStorage,
  GENDER_STEP
} from '../storage'

function Gender ({ currentPath }) {
  const history = useHistory()
  const [ selectedGender, setSelectedGender ] = useState(getOnboardingStorage(GENDER_STEP))
  const [ validationError, setValidationError ] = useState(null)

  const options = [
    { title: 'Mann', value: 'male', icon: MaleIcon },
    { title: 'Frau', value: 'female', icon: FemaleIcon },
  ]

  useEffect(() => {
    setValidationError(null)
  }, [ selectedGender ])

  const handleNextPage = () => {
    if (!selectedGender.value) {
      return setValidationError('Bitte wähle das Geschlecht')
    }

    setOnboardingStorage(GENDER_STEP, selectedGender)
    history.push(getNextOnboardingPage(currentPath))
  }

  return (
    <div>
      <div className="step-header">
        <div className='header-content'>
          <Link to={'/'}>
            <img src={backIcon} alt="back icon" />
          </Link>
          <div>
            <div className="headline">Anamnese</div>
            <div className="sub-headline">Wir erstellen deinen Plan.</div>
          </div>
        </div>
        <div className="actions">
          <span onClick={handleNextPage} className="main-button">Weiter</span>
        </div>
      </div>
      <div className="step-content">
        <Styles.MainContainer>
          <div className='section-titles-wrapper'>
            <div className="primary-title">Dein Geschlecht ist die Basis für den Kalorienbedarf.</div>
            <div className="accent-subtitle">Der Grundumsatz unterscheidet sich bei Mann und Frau.</div>
            <div className="small-subtitle">Wähle dein Geschlecht</div>
          </div>
          { validationError ? <div className="error-message">{validationError}</div> : '' }
          <div className="gender-options">
            {options.map(option => (
              <Styles.GenderOption
                key={option.value}
                onClick={() => setSelectedGender(option)}
                selected={selectedGender.value === option.value}
              >
                <div className="option-content">
                  <div className="icon">
                    <img src={option.icon} alt="" />
                  </div>
                  <div className="name">{option.title}</div>
                </div>
                <div className="option-checkbox">
                  <Checkbox
                    checked={selectedGender.value === option.value}
                    onChange={() => setSelectedGender(option)}
                  />
                </div>
              </Styles.GenderOption>
            ))}
          </div>
        </Styles.MainContainer>
      </div>
    </div>
  )
}

export default Gender
