import styled from "styled-components";

export const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;

  @media (max-width: 991.99px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
  }

  &.changeOrder {
    .text-content {
      order: 2;
    }
    
    @media (max-width: 991.99px) {
      display: flex;
      flex-direction: column;
      gap: 50px;
    }
  }
`;

export const Title = styled.h3`
  color: #142c1f;
  margin-bottom: 35px;
`;

export const Description = styled.p`
  color: #44554c;
`;

export const VisualContent = styled.div`
  img {
    width: 100%;
  }

  & > div {
    max-width: 600px;
    width: 100% !important;
    height: auto !important;
  }
`;

export const Action = styled.div`
  margin-top: 40px;

  @media (max-width: 600px) {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
`;
