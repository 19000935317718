export const categoryIcons = {
  'Vegetarisch': require('assets/images/onboarding/meals/categories/Vegetarisch.png'),
  'Vegan': require('assets/images/onboarding/meals/categories/Vegan.png'),
  'Fleischhaltig': require('assets/images/onboarding/meals/categories/Fleischhaltig.png'),
}

export const mealIcons = {
  'Apfel': require('assets/images/onboarding/meals/recipes/Apfel.jpg'),
  'Avocado': require('assets/images/onboarding/meals/recipes/Avocado.jpg'),
  'Banane': require('assets/images/onboarding/meals/recipes/Banane.jpg'),
  'Bandnudeln in Käse-Sahne Sauce': require('assets/images/onboarding/meals/recipes/Bandnudeln in Käse-Sahne Sauce.jpg'),
  'Belugalinsensalat': require('assets/images/onboarding/meals/recipes/Belugalinsensalat.jpg'),
  'Bratkartoffeln': require('assets/images/onboarding/meals/recipes/Bratkartoffeln.jpg'),
  'Brokkoli Couscous Pfanne': require('assets/images/onboarding/meals/recipes/Brokkoli Couscous Pfanne.jpg'),
  'Brot mit Avocado': require('assets/images/onboarding/meals/recipes/Brot mit Avocado.jpg'),
  'Chiapudding mit Himbeeren': require('assets/images/onboarding/meals/recipes/Chiapudding mit Himbeeren.jpg'),
  'Chili con carne': require('assets/images/onboarding/meals/recipes/Chili con carne.jpg'),
  'Salatbowl mit Ei': require('assets/images/onboarding/meals/recipes/Salatbowl mit Ei.jpg'),
  'Falafel mit Couscous-Salat': require('assets/images/onboarding/meals/recipes/Falafel mit Couscous-Salat.jpg'),
  'Gebackene Avocado mit Ei': require('assets/images/onboarding/meals/recipes/Gebackene Avocado mit Ei.jpg'),
  'Gnocchi-Gemüse Pfanne': require('assets/images/onboarding/meals/recipes/Gnocchi-Gemüse Pfanne.jpg'),
  'Granola': require('assets/images/onboarding/meals/recipes/Granola.jpg'),
  'Griechischer Salat mit Feta': require('assets/images/onboarding/meals/recipes/Griechischer Salat mit Feta.jpg'),
  'Hummus mit Falafel': require('assets/images/onboarding/meals/recipes/Hummus mit Falafel.jpg'),
  'Joghurt mit Früchten': require('assets/images/onboarding/meals/recipes/Joghurt mit Früchten.jpg'),
  'Klassisches Rührei': require('assets/images/onboarding/meals/recipes/Klassisches Rührei.jpg'),
  'Lachs mit Spargel': require('assets/images/onboarding/meals/recipes/Lachs mit Spargel.jpg'),
  'Magerquark': require('assets/images/onboarding/meals/recipes/Magerquark.jpeg'),
  'Maiswaffeln': require('assets/images/onboarding/meals/recipes/Maiswaffeln.jpg'),
  'Nudelpfanne mit Gemüse': require('assets/images/onboarding/meals/recipes/Nudelpfanne mit Gemüse.jpg'),
  'Nüsse': require('assets/images/onboarding/meals/recipes/Nüsse.jpg'),
  'Oatmeal mit Pfirsich und Himbeeren': require('assets/images/onboarding/meals/recipes/Oatmeal mit Pfirsich und Himbeeren.jpg'),
  'Oatmeal': require('assets/images/onboarding/meals/recipes/Oatmeal.jpg'),
  'Pfirsich': require('assets/images/onboarding/meals/recipes/Pfirsich.jpeg'),
  'Reis mit Mango': require('assets/images/onboarding/meals/recipes/Reis mit Mango.jpg'),
  'Reis mit gebratenem Tofu': require('assets/images/onboarding/meals/recipes/Reis mit gebratenem Tofu.jpg'),
  'Reiswaffeln': require('assets/images/onboarding/meals/recipes/Reiswaffeln.jpg'),
  'Rührei': require('assets/images/onboarding/meals/recipes/Rührei.jpg'),
  'Salat mit Avocado und Eier': require('assets/images/onboarding/meals/recipes/Salat mit Avocado und Eier.jpg'),
  'Salat mit Guacamole': require('assets/images/onboarding/meals/recipes/Salat mit Guacamole.jpg'),
  'Salat mit Nussmischung': require('assets/images/onboarding/meals/recipes/Salat mit Nussmischung.jpg'),
  'Salat mit Ziegenkäse und Birnen': require('assets/images/onboarding/meals/recipes/Salat mit Ziegenkäse und Birnen.jpg'),
  'Smoothiebowl': require('assets/images/onboarding/meals/recipes/Smoothiebowl.jpg'),
  'Spiegeleier': require('assets/images/onboarding/meals/recipes/Spiegeleier.jpg'),
  'Toast mit Ei und Avocado': require('assets/images/onboarding/meals/recipes/Toast mit Ei und Avocado.jpg'),
}
