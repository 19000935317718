import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from './AppPrevention.style'
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import videoSource from 'assets/videos/stats-dashboard.mp4'

const AppPrevention = ({ changeOrder = false, onLeadFormModal }) => {
  const history = useHistory()

  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={ changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap" }
    >
      <div className="text-content">
        <Chip text="Digitales Angebot" margin="0 0 30px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          App und Präventionskurs für deinen dauerhaften Erfolg.
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Mit über 1700 Rezepten und smarten Funktionen unterstützt dich die Ernährungsplaner-App im Alltag.
          Rezeptinspirationen sorgen dafür, dass deine Ernährungsumstellung abwechslungsreich bleibt.
          Im zertifizierten Präventionskurs lernst du zudem die wichtigsten Grundlagen einer gesunden Ernährung.
        </C.Description>

        <C.Action>
          <button className="btn-dark" onClick={() => history.push('/app')}>Mehr erfahren</button>
        </C.Action>
      </div>
      <C.VisualContent>
        <ReactPlayer
            url={videoSource}
            playsinline
            playing
            muted
            loop
          />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default AppPrevention
