import React from "react";
import * as F from "./PlanBox.style";
import * as G from "styles/General.Styles";
import GreenTickWhiteBg from "assets/images/Page2/Icon-check-green-white.svg";

const SinglePlan = ({ openLeadFormModal }) => {
  const list = [
    '1700 leckere Rezepte',
    'Einkaufsliste',
    'Eigener Ernährungsplan',
    'Nährwertanalyse',
    'Academy',
    'Und vieles mehr'
  ]

  return (
    <F.CoachingPlansBox className="plansBox">
      <F.PlanBox className="active">
        <F.PlanBoxHead>
          <F.TitleInfo className="active ct-text-block uppercase text-base font-normal">
            Ernährungsplaner-App
          </F.TitleInfo>
          <F.Title className="ct-text-block color-dark text-4xl mb-12 font-medium">
            28,99 €
          </F.Title>
          <F.SubTitle className="ct-text-block uppercase text-base font-normal">
            für 3 Monate
          </F.SubTitle>
        </F.PlanBoxHead>
        <F.PlanLowerBox>
          <G.PlanBoxIcons>
            {list.map(item => (
              <li>
                <G.PlanBoxIconBox>
                  <img src={GreenTickWhiteBg} alt="Tick" />
                </G.PlanBoxIconBox>
                <G.PlanBoxInformation className="ct-text-block text-base">
                  {item}
                </G.PlanBoxInformation>
              </li>
            ))}
          </G.PlanBoxIcons>
        </F.PlanLowerBox>
        <div className="button-wrapper">
          <button className="btn-dark" onClick={openLeadFormModal}>
            Ernährungsplan erstellen
          </button>
        </div>
      </F.PlanBox>
    </F.CoachingPlansBox>
  );
};

export default SinglePlan;
