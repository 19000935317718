import styled from 'styled-components'

export const OnboardingWrapperContainer = styled.div`
  background: #E5F5F2;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100vh;
  font-size: 16px;
  position: relative;

  .loader-container {
    height: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper-mobile-progress, .wrapper-desktop-progress {
    padding: 20px;
    border-radius: 20px;
    background: white;
    box-shadow: 0px 2px 5px #0000001a;
  }

  .wrapper-content {
    border-radius: 20px;
    background: white;
    box-shadow: 0px 2px 5px #0000001a;
  }

  .wrapper-mobile-progress {
    display: flex;
    flex-direction: column;

    .logo {
      display: flex;
      justify-content: center;
    }

    .mobile-steps-header {
      text-align: center;
      span {
        color: #142C1F;
        font-weight: 500;
      }
    }

    .mobile-steps {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .wrapper-desktop-progress {
    display: none;
    flex-direction: column;
    gap: 20px;

    .desktop-progress-headline {
      color: #142C1F;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
    }

    .desktop-steps-header {
      color: #142C1F;
      font-weight: 500;
    }
  }

  .wrapper-content {

    .step-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px;
      border-bottom: 1px solid #E2E4E9;

      .headline {
        color: #1C9D87;
        font-weight: bold;
      }
      
      .sub-headline {
        color: #142C1F;
        font-weight: bold;
      }
      
      .main-button {
        border: none;
        cursor: pointer;
        background: linear-gradient(110deg, #2ecf96 0%, #1c9d87 100%);
        background-size: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 5px;
        font-weight: 600;
        width: max-content;
        transition: all 0.2s ease-in-out;
        padding: 5px 20px;
      }

      .main-button:hover {
        background: linear-gradient(110deg, #2ecf96 0%, #1c9d87 100%);
        background-size: 150%;
        transform: translateY(-2px);
      }
    }

    .step-content {
      padding: 20px;
      margin-top: 30px;

      .section-titles-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .primary-title {
        font-family: "Rubik", sans-serif !important;
        font-optical-sizing: auto;
        font-style: normal;
        color: #142c1f;
        font-weight: 500;
        font-size: 20px;
        max-width: 500px;
      }

      .accent-subtitle {
        color: #818D88;
        max-width: 500px;
      }

      .small-subtitle {
        color: #142C1F;
        font-size: 14px;
        font-weight: 500;
        max-width: 500px;
      }
    }
  }

  @media (min-width: 1000px) {
    flex-direction: row;
    padding: 50px;

    .wrapper-mobile-progress {
      display: none;
    }
    
    .wrapper-desktop-progress {
      display: flex;
      width: 320px;
      position: fixed;
      height: calc(100vh - 100px);
      padding: 30px;
    }

    .wrapper-content {
      flex: 1;
      margin-left: 340px;

      .step-header {
        .sub-headline {
          font-size: 20px;
        }
        
        .header-content {
          display: flex;
          gap: 20px;
        }
      }
    }
  }
`
