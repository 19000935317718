import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from './KnowEachOther.style'
import KnowEachOtherBanner from 'assets/images/know-each-other.svg'

const KnowEachOther = ({ changeOrder = false, onLeadFormModal }) => {
  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={ changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap" }
    >
      <div className="text-content">
        <Chip text="Unverbindlich" margin="0 0 30px 0" />
        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Lernen wir uns jetzt kennen
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          In einem unverbindlichen Gespräch lernen wir uns persönlich kennen. Dabei führe ich eine IST-Analyse durch und erstelle direkt deinen individuellen Ernährungsplan,
          den du kostenlos erhältst. 
        </C.Description>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Im Anschluss steht es dir frei, ob du mit mir und dem Coaching PLUS oder Coaching PRO sowie meiner kontinuierlichen Unterstützung starten möchtest.
        </C.Description>

        <C.Action>
          <button className="btn-dark" onClick={() => onLeadFormModal()}>Erstgespräch vereinbaren</button>
        </C.Action>
      </div>

      <C.VisualContent>
        <img src={KnowEachOtherBanner} alt="" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default KnowEachOther
