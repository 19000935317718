export const menuItems = [
  {
    name: 'Mein Angebot',
    url: '/mein-coaching',
    subItems: null
  },
  {
    name: 'Über mich',
    url: '/ueber-mich',
    subItems: null
  },
  {
    name: 'Mein Coaching',
    url: '/coaching',
    subItems: [
      {
        name: 'Mein Coaching',
        url: '/coaching',
      },
      {
        name: 'Erstgespräch',
        url: '/erstgespraech',
      },
      {
        name: 'Coaching PLUS',
        url: '/coaching-plus',
      },
      {
        name: 'Coaching PRO',
        url: '/coaching-pro',
      }
    ]
  },
  {
    name: 'Digitale Tools',
    url: '/digitale-tools',
    subItems: [
      {
        name: 'Digitale Tools',
        url: '/digitale-tools',
      },
      {
        name: 'Online-Kurs',
        url: '/online-kurs',
      },
      { type: 'divider' },
      {
        name: 'App',
        url: '/app',
        type: 'outline'
      },
    ]
  },
  // {
  //   name: 'Online-Kurs',
  //   url: '/online-kurs',
  //   subItems: null
  // },
  // {
  //   name: 'App',
  //   url: '/app',
  //   subItems: [
  //     {
  //       name: 'App',
  //       url: '/app',
  //     },
  //     { type: 'divider' },
  //     {
  //       name: 'App Erstellen',
  //       url: '/app-erstellen',
  //       type: 'outline'
  //     }
  //   ]
  // }
]
