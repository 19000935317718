import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

// Components
import Navbar from "components/Navbar/Navbar";
import StickyNavbar from "components/StickyNavbar/StickyNavbar";
import FooterNew from "components/FooterNew/FooterNew";
import DietMadeEasy from "components/DietMadeEasy/DietMadeEasy";
import SectionWrapper from "components/General/sectionWrapper/SectionWrapper";
import StandardProgram from "components/StandardProgram/StandardProgram";
import NutritionCoach from "components/NutritionCoach/NutritionCoach";
import YoyoEffect from "components/YoyoEffect/YoyoEffect";
import CoachFromFoodiary from "components/CoachFromFoodiary/CoachFromFoodiary";
import BenefitsGlance from "components/BenefitsGlance/BenefitsGlance";
import StartNutritionChange from "components/StartNutritionChange/StartNutritionChange";
import StartTogether from "components/StartTogether/StartTogether";
import HealthySteps from "components/HealthySteps/HealthySteps";
import PaymentModal from "modals/PaymentModal/PaymentModal";
import FailureDialog from "modals/FailureDialog";
// Redux
import * as CoachActions from "redux/coach/coach.actions";
import * as Routes from "routes/routes";
// Styles
import * as C from "./coachingProApp.styles";
import * as G from "styles/General.Styles";
import InitialConsultationBox from "components/InitialConsultationBox/InitialConsultationBox";
import hook from "assets/images/hook.svg";
import IntelligentRecipeFilter from "components/IntelligentRecipeFilter/IntelligentRecipeFilter";
import CountlessFunctions from "components/CountlessFunctions/CountlessFunctions";
import FunctionsFlow from "components/FunctionsFlow/FunctionsFlow";
import DailyRoutine from "components/DailyRoutine/DailyRoutine";
import CoachingProFeatures from "components/CoachingProFeatures/CoachingProFeatures";
import FreeInitialConsultation from "components/FreeInitialConsultation/FreeInitialConsultation";
import CoachingGlance from "components/CoachingPlans/CoachingPlans";
import MealPlanTools from "components/MealPlanTools/MealPlanTools";
import DeliciousHealth from "components/DeliciousHealth/DeliciousHealth";
import FullSupportWithPersonalCoach from "components/FullSupportWithPersonalCoach/FullSupportWithPersonalCoach";
import DietPlanning from "components/DietPlanning/DietPlanning";
import LeadSurveyModal from "modals/LeadSurveyModal/LeadSurveyModal";
import { IS_LOCAL } from "configs/utils";
import FreeCourseSteps from "components/FreeCourseSteps/FreeCourseSteps";
import ThumbsUpIcon from 'assets/images/thumbs-up-icon.svg'
import LetsGoIcon from 'assets/images/letsgo-icon.svg'
import PeacIcon from 'assets/images/peace-icon.svg'
import PlanBoxApp from "components/PlanBoxApp/PlanBoxApp";

const CoachingProApp = (props) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [openLeadModal, setOpenLeadModal] = useState(false);

  const coach = useSelector((state) => state.coach.coach);

  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    const { success } = queryString.parse(search);
    if (success == "true") {
      setSuccessDialog(true);
      history.push(Routes.SUCCESS);
    }
    if (success == "false") {
      setCancelDialog(true);
    }
  }, [search]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (coach) return

    const coach_website_url = IS_LOCAL
      ? 'https://ronja-fci49818.foodiary.app'
      : `${window.location.protocol}//${window.location.host}`

    dispatch(
      CoachActions.trainerIdRequest({
        formData: { coach_website_url },
        callback: (res) => {
          dispatch(
            CoachActions.coachDataRequest({
              formData: { trainerID: res?.trainerID },
              callback: () => null,
            })
          );
        },
      })
    );
  }, [dispatch, coach]);

  return (
    <>
      <C.Header>
        <C.HeaderInner>
          <G.ContainerNavbar>
            <StickyNavbar openSurveyModal={() => setOpenLeadModal(!openLeadModal)} />
          </G.ContainerNavbar>
          <G.Container>
            <C.HeaderContentWrapper>
              <C.HeaderContent>
                <C.Chip>FOODIARY APP</C.Chip>
                <C.HeaderTitle className="ct-text-block font-bold color-dark text-center mt-8 text-5xl">
                  Smarte App
                  <br />
                  für deinen Alltag
                </C.HeaderTitle>
                <C.HeaderDescription className="ct-text-block color-paragraph text-base text-center">
                  Verwende die Ernährungsplaner-App, um deinen eigenen Ernährungsplan zu
                  <br />
                  erstellen und im Alltag deine Ernährung gesünder zu gestalten.
                </C.HeaderDescription>
              </C.HeaderContent>
              <div className="hook-block">
                <img width="100" src={hook} alt="hook" />
              </div>
            </C.HeaderContentWrapper>
          </G.Container>
        </C.HeaderInner>
      </C.Header>

      <G.Container>
        <SectionWrapper margin="20rem 0 0" mobileMargin="5 0 0">
          <IntelligentRecipeFilter onLeadFormModal={() => setOpenLeadModal(true)} />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="5 0 0">
          <FreeCourseSteps onLeadFormModal={() => setOpenLeadModal(true)} list={[
              {
                title: 'Analyse deiner Daten',
                description: 'Klicke auf den Button Ernährungsplan erstellen und in wenigen Klicks wird dein aktueller „IST Zustand“ analysiert. Hier gehen wir zudem auf deinen Tagesablauf und deine präferierten Lebensmittel ein.',
                icon: ThumbsUpIcon
              },
              {
                title: 'Dein Ernährungsplan',
                description: 'Auf Basis deines „IST - Zustand“ wir deine Ernährungsplan erstellt und dir direkt vorgestellt. Du erfährst, welche Ernährungsform für dich geeignet ist und wie sich deine Kalorien zusammen setzen. ',
                icon: PeacIcon
              },
              {
                title: `Let's go!`,
                description: 'Nach dem Start erhältst du direkt Zugriff auf die Smarte Ernährungsplaner App. Du kannst die kommenden Tage planen, dich mit den Funktionen vertraut machen & bereits am nächsten Morgen starten.',
                icon: LetsGoIcon
              }
            ]}>
              <span>Wenige Klicks</span>
              <br />
              Zu deinem eigenen Ernährungsplan 🤘
            </FreeCourseSteps>
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <MealPlanTools />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <FunctionsFlow />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <DeliciousHealth onLeadFormModal={() => setOpenLeadModal(true)} />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <DietPlanning />
        </SectionWrapper>

        <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <PlanBoxApp openLeadFormModal={() => setOpenLeadModal(true)} />
        </SectionWrapper>

        {/* <SectionWrapper margin="20rem 0 0" mobileMargin="5rem 0 0">
          <FullSupportWithPersonalCoach onLeadFormModal={() => setOpenLeadModal(true)} />
        </SectionWrapper> */}

        <PaymentModal isOpen={openPaymentModal} onClose={() => setOpenPaymentModal(false)} couchProfile={coach} />
        <FailureDialog open={cancelDialog} close={() => setCancelDialog(false)} />

      </G.Container>

      <SectionWrapper margin="7.8rem 0 0">
        <InitialConsultationBox openLeadFormModal={() => setOpenLeadModal(true)} />
      </SectionWrapper>

      <FooterNew />

      {coach && (
        <LeadSurveyModal
          isOpen={openLeadModal}
          onClose={() => setOpenLeadModal(false)}
          coachProfile={coach}
        />
      )}
    </>
  );
};

export default CoachingProApp;
