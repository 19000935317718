import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from './DigitalTools.style'
import CheckMarkFilled from 'assets/images/check-bullet-fill.svg'
import videoSource from 'assets/videos/digital-tools.mp4'
import ReactPlayer from "react-player";

const DigitalToolsCover = ({ changeOrder = false, onLeadFormModal }) => {
  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={ changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap" }
    >
      <div className="text-content">
        <Chip bg={'white'} color={'#1C9D87'} text="Digitale Tools" margin="0 0 30px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Digitale Tools für eine gesunde Ernährung.
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Diese digitalen Tools helfen dir, deine Ernährung im Alltag nachhaltig umzustellen und bieten dir gleichzeitig wertvolle Weiterbildungsmöglichkeiten im Bereich gesunde Ernährung.
        </C.Description>

        <C.List>
          <div>
            <span>
              <img src={CheckMarkFilled} alt="" />
            </span>
            <span>Ernährungsplaner-App mit über 1700 Rezepten</span>
          </div>
          <div>
            <span>
              <img src={CheckMarkFilled} alt="" />
            </span>
            <span>Präventiver Online-Kurs mit Kostenrückerstattung</span>
          </div>
        </C.List>

      </div>
      <C.VisualContent>
        <ReactPlayer
          url={videoSource}
          playsinline
          playing
          muted
          loop
        />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default DigitalToolsCover
