import { Redirect } from 'react-router-dom'
import { clearOnboardingStorage } from '../storage'
import { useEffect } from 'react'

function Start () {
  useEffect(() => {
    clearOnboardingStorage()
  }, [])
  return <Redirect to="/onboarding/gender" />
}

export default Start
