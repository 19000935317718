import {
  GENDER_STEP,
  GOAL_STEP,
  BODY_DETAILS_STEP,
  DAILY_ROUTINE_STEP,
  ACTIITY_LEVEL_STEP,
  TRAINING_DAYS_STEP,
  PREFERRED_MEALS_STEP,
} from './storage'

export {
  getPreparedRegistrationData
}

function getPreparedRegistrationData ({
  onboarding_storage,
  coachInfo,
  userInfo,
}) {

  // console.log('coachInfo : ', coachInfo)
  // console.log('userInfo : ', userInfo)

  return {
    ...getStorageData(onboarding_storage),
    email: userInfo.email,
    prename: userInfo.firstName,
    surname: userInfo.lastName,
    mobile: `${userInfo.selectedCountryCode}${userInfo.phoneNumber}`,
    grant_type: 'password',
    type: 'web',
    trainerID: coachInfo.trainerID
  }
}

function getStorageData (storage) {
  // sex
  const sex = storage[GENDER_STEP].value

  // aimId
  const aimID = storage[GOAL_STEP].registrationValue

  // -- body details
  const {
    height,
    weight,
    dateOfBirth: birthday
  } = storage[BODY_DETAILS_STEP]

  // -- daily routines
  const routines = getFormattedRoutines(storage[DAILY_ROUTINE_STEP])

  // -- activity level
  const movementtypes = getFormattedMovementTypes(storage[ACTIITY_LEVEL_STEP])

  // -- training days
  const trainingData = getFormattedTrainingDays(storage[TRAINING_DAYS_STEP])

  // -- 5 meals with category prefix
  const eatingHabits = getFormattedEatingHabits(storage[PREFERRED_MEALS_STEP])

  return {
    sex,
    aimID,
    height,
    weight,
    birthday,
    routines,
    movementtypes,
    nutritiontypeID: 2,
    ...trainingData,
    ...eatingHabits
  }
}

function getFormattedEatingHabits ({ categoryOption, meals }) {
  return {
    eatingHabit1: `registration.eatingHabit.${categoryOption.requestValue}.${meals.meal1.value}`,
    eatingHabit2: `registration.eatingHabit.${categoryOption.requestValue}.${meals.meal2.value}`,
    eatingHabit3: `registration.eatingHabit.${categoryOption.requestValue}.${meals.meal3.value}`,
    eatingHabit4: `registration.eatingHabit.${categoryOption.requestValue}.${meals.meal4.value}`,
    eatingHabit5: `registration.eatingHabit.${categoryOption.requestValue}.${meals.meal5.value}`,
  }
}

function getFormattedTrainingDays ({
  fixedTrainingDays: isTraining,
  fixedTrainingDaysNumber: totalTrainingDays,
  dayOptions
}) {

  if (isTraining) return { isTraining, trainingdays: totalTrainingDays }

  const trainingdays = dayOptions.reduce((acc, curr) => (
    curr.selected ? [ ...acc,  curr.requestDayId] : acc
  ), [])

  return { trainingdays }
}

function getFormattedMovementTypes ({ boxOptions }) {
  const movementtypes = boxOptions.map(({ movementtypeID, hours }) => ({
    movementtypeID,
    minutes: hours * 60
  }))
  return movementtypes
}

function getFormattedRoutines ({ routines: storageRoutines }) {
  const formattedRoutines = storageRoutines.reduce((acc, storageRoutine) => {
    let { name: routineName, schedule } = storageRoutine
    schedule = schedule.map(({ dayroutineID, value: time }) => ({ dayroutineID, time }))
    acc[routineName] = [ ...schedule ]
    return acc
  }, {})
  return formattedRoutines
}

// request 1:

// {
//   "aimID": 1,
//   "nutritiontypeID": 2,
//   "sex": "male",
//   "height": 140,
//   "weight": 140,
//   "birthday": "2024-12-12",
//   "email": "test@gmail.com",
//   "prename": "test",
//   "surname": "test",
//   "mobile": "+4923892839283",
//   "grant_type": "password",
//   "isTraining": true,
//   "trainingdays": 1,
//   "routines": {
//     "test": [
//       { "dayroutineID": 1, "time": "07:00" },
//       { "dayroutineID": 2, "time": "12:00" },
//       { "dayroutineID": 3, "time": "17:00" },
//       { "dayroutineID": 4, "time": "22:00" }
//     ]
//   },
//   "movementtypes": [
//     { "minutes": 1440, "movementtypeID": 1 },
//     { "minutes": 0, "movementtypeID": 2 },
//     { "minutes": 0, "movementtypeID": 3 },
//     { "minutes": 0, "movementtypeID": 4 },
//     { "minutes": 0, "movementtypeID": 5 },
//     { "minutes": 0, "movementtypeID": 6 }
//   ],
//   "eatingHabit3": "registration.eatingHabit.vegetarian.Eierragout",
//   "eatingHabit4": "registration.eatingHabit.vegetarian.Nusse",
//   "eatingHabit5": "registration.eatingHabit.vegetarian.KlassischesRuhrei",
//   "type": "web",
//   "trainerID": 1
// }

// request 2 :
// {
//   "sex": "male",
//   "aimID": 1,
//   "height": 140,
//   "weight": 140,
//   "nutritiontypeID": 2,
//   "birthday": "2024-12-12",
//   "email": "test@gmail.com",
//   "prename": "test",
//   "surname": "test",
//   "mobile": "+49283787238723",
//   "grant_type": "password",
//   "trainingdays": [1, 2],
//   "routines": {
//     "test": [
//       { "dayroutineID": 1, "time": "07:00" },
//       { "dayroutineID": 2, "time": "12:00" },
//       { "dayroutineID": 3, "time": "17:00" },
//       { "dayroutineID": 4, "time": "22:00" }
//     ]
//   },
//   "movementtypes": [
//     { "minutes": 1440, "movementtypeID": 1 },
//     { "minutes": 0, "movementtypeID": 2 },
//     { "minutes": 0, "movementtypeID": 3 },
//     { "minutes": 0, "movementtypeID": 4 },
//     { "minutes": 0, "movementtypeID": 5 },
//     { "minutes": 0, "movementtypeID": 6 }
//   ],
//   "eatingHabit3": "registration.eatingHabit.vegetarian.Eierragout",
//   "eatingHabit4": "registration.eatingHabit.vegetarian.Nusse",
//   "eatingHabit5": "registration.eatingHabit.vegetarian.KlassischesRuhrei",
//   "type": "web",
//   "trainerID": 1
// }
