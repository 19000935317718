import React from "react";
import * as F from "./FeaturesCards.style";
import SmileyOne from "assets/images/hand-shake.svg";
import SmileyTwo from "assets/images/new/Smiley-2.png";
import SmileyThree from "assets/images/new/Smiley-3.png";

const FeaturesCards = () => {
  const Cards = [
    {
      image: SmileyOne,
      title: "Kostenloses Erstgespräch",
      description: "Lass uns in einem kostenlosen Zoom-Call kennenlernen und gemeinsam deinen Ernährungsplan erstellen!",
    },
    {
      image: SmileyTwo,
      title: "Coaching PLUS",
      description:
        "Erstgespräch mit wertvollen Tipps & Tricks sowie 3-monatiger digitaler Betreuung in der Lernacademy.",
    },
    {
      image: SmileyThree,
      title: "Coaching PRO",
      description:
        "Persönliche 3-monatige Unterstützung mit Ernährungsplaner-App, Lernacademy und regelmäßigem Austausch.",
    },
  ];

  return (
    <F.Wrap>
      <F.CardsWrap>
        {Cards.map((item, i) => (
          <F.Card key={i}>
            <img src={item?.image} alt="pro" />
            <F.CardTitle className="font-medium color-dark text-center">
              {item?.title}
            </F.CardTitle>
            <F.CardDescription>
              {item.description}
            </F.CardDescription>
          </F.Card>
        ))}
      </F.CardsWrap>
    </F.Wrap>
  );
};

export default FeaturesCards;
