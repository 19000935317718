import styled from "styled-components"

export const Card = styled.div`
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  background: #f0f4f3;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`

export const Title = styled.h3`
  color: #1c9d87;
`

export const Description = styled.p`
  color: #44554c;
`

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
    width: 60px;
  }
`
