import React from "react";
import Chip from "components/General/formComponents/chip/Chip";
import * as C from './CoachingCover.style'
import CoachingCoverBanner from 'assets/images/coaching-banner.svg'

const CoachingCover = ({ changeOrder = false, onLeadFormModal }) => {
  return (
    <C.Wrap
      changeOrder={changeOrder}
      className={ changeOrder ? "NutritionCoachWrap changeOrder" : "NutritionCoachWrap" }
    >
      <div className="text-content">
        <Chip color={'#1C9D87'} bg={'white'} text="Mein Coaching" margin="0 0 30px 0" />

        <C.Title className="ct-headline foodiary-h2 color-dark text-4xl">
          Ich begleite deine Ernährungsumstellung
        </C.Title>

        <C.Description className="ct-text-block color-paragraph font-normal text-base">
          Ich begleite dich als dein persönlicher Ernährungsberater und Coach auf deinem Weg. Mit Unterstützung der Lernacademy,
          der Ernährungsplaner-App und mir als deinem Ansprechpartner überwinden wir gemeinsam die Herausforderungen
          des Alltags und integrieren eine gesunde und zielorientierte Ernährung in dein Leben.
        </C.Description>

        <C.Action>
          <button className="btn-dark" onClick={() => onLeadFormModal()}>Erstgespräch vereinbaren</button>
        </C.Action>

      </div>
      <C.VisualContent>
        <img src={CoachingCoverBanner} alt="" />
      </C.VisualContent>
    </C.Wrap>
  );
};

export default CoachingCover
